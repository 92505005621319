import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';

import { menuController } from '@ionic/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit{

  public isMenuEnabled:boolean = true;
  public selectedIndex = 0;
  user:any;

  constructor(
    private platform: Platform,
    private router: Router,
  ) {}

  title = 'Home';
  ngOnInit() {
    this.user  = JSON.parse(localStorage.getItem('user'))
  }

  menuItems = [
    {
      title: 'Home',
      icon: 'home',
      path: '/'
    },
    {
      title: 'Products',
      icon: 'list',
      path: '/products'
    },
    {
      title: 'Cart',
      icon: 'cart',
      path: '/cart'
    },
    {
      title: 'Distributors',
      icon: 'git-branch',
      path: '/distributor'
    },
    {
      title: 'Influencers',
      icon: 'glasses',
      path: '/influencer'
    },
    {
      title: 'Profile',
      icon: 'person',
      path: '/profile'
    },
    {
      title: 'About Us',
      icon: 'information',
      path: '/about'
    },
    {
      title: 'Policy',
      icon: 'document-text',
      path: '/policy'
    }
  ];

  setTitle(title) {
    this.title = title;
  }

  close() {
    menuController.toggle();
  }
}
