// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'zeirho-trial-1',
    appId: '1:10926184932:web:341ef4594d4cf1d2008ff1',
    storageBucket: 'zeirho-trial-1.appspot.com',
    apiKey: 'AIzaSyCmyFBI8916JMTqn0On91K3245El9iArss',
    authDomain: 'zeirho-trial-1.firebaseapp.com',
    messagingSenderId: '10926184932',
    measurementId: 'G-8S9RV330LQ',
  },
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
